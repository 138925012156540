<template>
  <v-container>
    <v-divider />
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-layout wrap row>
        <v-flex xs12 md6>
          <v-text-field
            label="السؤال"
            placeholder="السؤال"
            v-model="question"
            :rules="[v => !!v || 'السؤال مطلوب']"
            required
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6>
          <v-switch
            v-model="manyAnswers"
            label="متعدد الإجابات"
            color="info"
            :value="true"
            hide-details
          ></v-switch>
        </v-flex>

        <v-flex xs12 md6 v-if="manyAnswers">
          <v-text-field
            clearable
            label="الاختيارات"
            placeholder="الاختيارات"
            v-model="chosses"
            @keyup.enter="addToAnswers"
            outlined
          ></v-text-field>
          <v-btn
            small
            @click="addToAnswers"
            :disable="chosses == ''"
            style="background:gray;"
            dark
          >
            <b>أضافة اختيار</b>
          </v-btn>
        </v-flex>
        <v-flex xs12 md6 v-if="manyAnswers">
          <v-chip
            close
            @click:close="removeFromAnswers(answer)"
            class="ma-2"
            v-for="(answer, index) in answers"
            :key="index"
          >
            {{ answer }}
          </v-chip>
        </v-flex>
      </v-layout>
      <v-layout wrap row>
        <v-flex xs12 md12>
          <v-btn
            small
            class="text-center"
            style="color:white;"
            fab
            color="green"
            @click.prevent="addQuestion"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <v-layout wrap row>
        <v-flex xs12 md12>
          <v-simple-table v-if="questions.length > 0">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title
                  ><b><u>الأسئلة المضافة</u></b></v-toolbar-title
                >
              </v-toolbar>
            </template>
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, index) in questions" :key="index">
                  <td style="font-size:14px">
                    <label> السؤال : {{ item.question.QuestionText }} </label
                    ><br />
                    الخيارات :
                    <span v-for="(answr, index) in item.allanswer" :key="index"
                      >"{{ answr.AnswerText }}"
                    </span>
                    <v-icon
                      color="red"
                      style="float:left"
                      text-color="white"
                      small
                      @click="deleteQuestion(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "AddQuestion",
  data() {
    return {
      valid: true,
      questions: [],
      chosses: "",
      answers: [],
      manyAnswers: false,
      question: ""
    };
  },
  methods: {
    deleteQuestion(ques) {
      for (let i = 0; i < this.questions.length; i++) {
        if (
          this.questions[i].question.QuestionText == ques.question.QuestionText
        )
          this.questions.splice(i, 1);
      }
      this.$emit("saveQuestions", this.questions);
    },
    removeFromAnswers(data) {
      for (let i = 0; i < this.answers.length; i++) {
        if (this.answers[i] == data) this.answers.splice(i, 1);
      }
    },
    addToAnswers() {
      if (this.chosses == "") return;
      if (!this.answers.includes(this.chosses)) {
        this.answers.push(this.chosses);
      }
      this.chosses = "";
    },
    reset() {
      this.manyAnswers = false;
      this.questions = [];
      this.question = "";
      this.answers = [];
      this.answer = "";
      this.$refs.form.reset();
    },
    addQuestion() {
      if (this.$refs.form.validate()) {
        let ans = [];
        this.answers.forEach(el => {
          ans.push({ AnswerText: el });
        });
        this.questions.push({
          question: {
            QuestionText: this.question,
            TypeId: this.manyAnswers ? 1 : 2
          },
          allanswer: ans
        });
        this.$emit("saveQuestions", this.questions);
        this.manyAnswers = false;
        this.question = "";
        this.answers = [];
        this.answer = "";
        this.$refs.form.reset();
      }
    }
  }
};
</script>
