<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex class="mb-5">
        <v-card elevation="4" width="1000" class="mx-auto">
          <v-card-title primary-title>
            <h4 class="headline mb-0"><b>إضافة استبيان</b></h4>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>
   
          <v-card-text>
            <v-layout
              row-wrap
              v-for="(question, index) in ansques"
              :key="index"
            >
              <v-flex
                xs12
                md12
                class="mt-3"
                v-if="question.question.TypeId == 1"
              >
                <v-flex xs12 md12>
                  <v-text-field
                    label="عنوان السؤال"
                    @change="changeQuestions(question, index)"
                    placeholder="عنوان السؤال"
                    v-model="question.question.QuestionText"
                    required
                    clearable
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    clearable
                    v-for="(answer, ind) in question.allanswer"
                    :key="ind"
                    label="الاختيار"
                    placeholder="الاختيار"
                    v-model="answer.AnswerText"
                    @change="changeAnswers(index, ind)"
                    required
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md12>
                  <v-btn
                    small
                    class="text-center"
                    style="color:white;"
                    fab
                    color="info"
                    @click.prevent="addAnswer(question)"
                  >
                    <v-icon dark>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-flex>
                <v-divider />
              </v-flex>
              <v-flex xs12 md12 class="mt-3" v-else>
                <v-text-field
                  label="عنوان السؤال"
                  placeholder="عنوان السؤال"
                  v-model="question.question.QuestionText"
                  required
                  clearable
                  @change="changeQuestions(question, index)"
                  outlined
                ></v-text-field>
                <v-divider />
              </v-flex>
            </v-layout>

            <addQuestions
              @saveQuestions="addQuestion($event)"
              v-if="role == 'Admin'"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="save"
              style="background:#58dd5b;color:white;margin-left:5px;"
              dark
            >
              <b>حفظ</b>
            </v-btn>
            <!--    <v-btn @click="reset" style="background:#5867dd;color:white;margin-left:5px;" dark>
                            <b>إعادة تعيين</b>
                        </v-btn>
                        <v-btn @click="$router.go(-1)" style="background:gray;" dark>
                            <b>رجوع</b>
                        </v-btn>-->
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import addQuestions from "./addQuestions.vue";
export default {
  name: "AddQuestionnaire",
  components: {
    addQuestions
  },
  data() {
    return {
      questions: [],
      ansques: "",
      allAnswers: [],
      addQuestions: []
    };
  },
  methods: {
    changeAnswers(questionIndex, answerIndex) {
      let questionsAnswers = this.ansques[questionIndex].allanswer;
      if (questionsAnswers[answerIndex].AnswerText == "")
        this.ansques[questionIndex].allanswer.splice(answerIndex, 1);
    },
    changeQuestions(question, index) {
      if (question.question.QuestionText == "") this.ansques.splice(index, 1);
    },
    async getLastQuestionnaire() {
      this.questionnaire = null;
      this.questions = [];
      await this.ApiService.get("Questionnaire/GetLastQuestionnaire")
        .then(res => {
          var questions = res.data.responseData.questionnaire.questions;
          this.questionnaire = res.data.responseData.questionnaire;
          questions.forEach(el => {
            if (el != null) this.questions.push(el);
          });
          this.addprevoiusQuestions(this.questions);
        })
        .catch(() => {});
    },
    addprevoiusQuestions(questions) {
      this.allAnswers = [];
      this.ansques = [];
      this.addQuestions = [];
      questions.forEach(el => {
        if (el.typeId == 1) {
          let ans = [];
          if (el.ansques.length > 0)
            el.ansques.forEach(elm => {
              ans.push({ AnswerText: elm.ans.answerText });
            });
          this.allAnswers.push({
            question: {
              QuestionText: el.questionText,
              TypeId: 1
            },
            allanswer: ans
          });
        } else {
          this.allAnswers.push({
            question: {
              QuestionText: el.questionText,
              TypeId: 2
            },
            allanswer: ""
          });
        }
        this.ansques = this.allAnswers;
      });
    },
    reset() {
      this.getLastQuestionnaire();
    },
    addQuestion(event) {
      this.addQuestions = [];
      this.addQuestions = event;
    },
    checkEmpty(question) {
      let exitEmpyte = false;
      question.ansques.forEach(el => {
        if (el.ans.answerText == "") exitEmpyte = true;
      });
      if (!exitEmpyte) question.ansques.push({ ans: { answerText: "" } });
    },
    addAnswer(question) {
      let emp = false;
      question.allanswer.forEach(el => {
        if (el.AnswerText == "") emp = true;
      });
      if (!emp) question.allanswer.push({ AnswerText: "" });
    },
    async save() {
      if (this.addQuestions.length > 0) {
        this.addQuestions.forEach(elc => {
          this.ansques.push(elc);
        });
      }
      if (this.ansques == "") {
        this.hel.showSnackMsg("لا توجد أسئلة مضافة للاستبيان", "#5867dd", 2000);
        return;
      } else {
        let data = {
          questionnaire: {
            CreatorId: this.user.id,
            Title: "استبيان رضى العملاء",
            Date: this.date
          },
          ansques: this.ansques
        };
        await this.ApiService.post(
          "Questionnaire/AddQuestionnaire?creator=" + this.user.id,
          data
        )
          .then(() => {
            this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
            //    this.getLastQuestionnaire()
            this.reset();
          })
          .catch(() => {
            this.hel.showSnackMsg("فشلت العملية", "#af1616", 2000);
          });
      }
    }
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");

    this.getLastQuestionnaire();
  }
};
</script>
<style scoped>
p {
  font-size: 15px;
}
</style>
